import tabbar from '@/components/tabbar';
import { Popup, Loading, Toast, NavBar, Icon } from 'vant';
import apis from '../utils/apis'
const wx = require('weixin-js-sdk');
export default {
  name: 'skiHome',
  components: {
    'van-popup': Popup,
    'van-loading': Loading,
    'van-nav-bar': NavBar,
    'van-icon': Icon,
    tabbar,
  },
  data () {
    return {
      typeObj: {
        ticket: ['jdmp', 'SD', 'MP', 'GGCP'],
        enjoy: ['syem', 'WQP', 'zyems', 'yyss', 'wanle'],
        hotel: ['jd',],
        meal: ['ms',],
        gift: ['lswc',],
      }, // 类型编码集合
      ticket: [],
      enjoy: [],
      hotel: [],
      meal: [],
      gift: [],
      special: [],
      showCoupon: false,//  显示消费劵弹窗
      showMessage: false, // 显示活动介绍弹窗
      loading: false,
      couponInfo: {}, // 消费劵信息
      list: {}, //活动规则
      backApp: 0,
      couponList: {}, //我的优惠券列表
    }
  },
  watch: {
    showCoupon (n) {
      if (!n) {
        sessionStorage.setItem('hasCloseCoupon', 1)
      }
    }
  },
  methods: {
    couponListGoods (row) {
      this.$router.push({
        path: '/couponListGoods',
        query: {
          couponId: row.couponId,
          couponName: row.couponName,
        }
      })
    },
    // 分页查询优惠商品
    loadList (name) {
      this.$post2({
        url: apis.pagePreferentialCommodity,
        params: {
          "data": {
            "includeCommodityTypeCodes": this.typeObj[name],
          },
          "pageIndex": 1,
          "pageSize": 99,
          token: this.$global.token

        },
      }).then(res => {
        this[name] = res.data.data
        if (this[name].length > 0) {
          this[name].map(item => {
            let arr = []
            if (item.tags) {
              arr = item.tags.split(',')
            }
            item.tags = arr
          })
        }
        console.log(this[name])
        this.loading = false
      }).catch(err => {
        this.loading = false
        Toast(err.message);
      });
    },
    //仅查用户有消费劵的商品
    loadSpecial () {
      this.$post2({
        url: apis.pagePreferentialCommodity,
        params: {
          "data": {
            'onlyHasCoupon': true
          },
          "pageIndex": 1,
          "pageSize": 1,
          token: this.$global.token

        },
      }).then(res => {
        if (res.data.data.length > 0) {
          this.special = res.data.data[0]
          this.special.CouponPrcice = this.special.sellPrice - this.special.postCouponPrice
          this.loading = false
          if (this.special.postCouponPrice) {
            this.couponInfo = this.special
            this.couponIs(this.couponInfo) // 是否展示消费劵弹窗
          }
        }
      }).catch(err => {
        this.loading = false
        Toast(err.message);
      });
    },
    // 获取预定须知
    getInfo () {
      this.$get({
        url: apis.findByColumnCode,
        params: {
          columnCode: 'bxjyhgz',
          token: this.$global.token,
        },
      }).then(rsp => {
        this.list = rsp;
      });
    },
    /**自营 票务跳转 */
    goUrl (row) {
      this.$router.push({
        path: '/submitOrderQw',
        query: {
          commodityId: row.commodityId,
          sellerId: row.sellerId,
          isSelfSupport: row.sellerIsSelfSupport, //  是否自营
          isRealName: row.sellerIsRealName, //  是否实名
        }
      })
    },
    /**酒店跳转 */
    goUrlHotel (row) {
      //
      this.$router.push({
        path: '/hotelInner',
        query: {
          commodityId: row.commodityId,
          sellerId: row.sellerId
        }
      })
    },
    /**零售跳转 */
    goUrlls (row) {
      this.$router.push({
        path: '/goodsInfo',
        query: {
          commodityId: row.commodityId
        }
      })
    },
    /**消费劵跳转 */
    goUrlSpecial (row) {
      this.showCoupon = false
      if (row.commodityKind == 1) {
        this.goUrl(row)
      } else if (row.commodityKind == 3) {
        this.goUrlHotel(row)
      } else if (row.commodityKind == 4) {
        this.goUrlls(row)
      } else {
        Toast('未获取到商品类型，请联系管理员配置商品类型');
      }
    },

    /**
     * 是否展示消费劵弹窗
     * 第一次展示存在sessionStorage中
     */
    couponIs (info) {
      let falg = sessionStorage.getItem('isCoupon') || false
      console.log('sessionStorage  isCoupon:' + falg)
      if (!falg) {
        this.openCoupon()
        sessionStorage.setItem('isCoupon', info)
      }
    },
    /** 显示消费劵 */
    openCoupon () {
      if (sessionStorage.getItem('hasCloseCoupon')) {
        this.showCoupon = false
      } else {
        this.showCoupon = true
      }
    },
    /** 显示活动介绍 */
    openPop () {
      this.showMessage = true
    },
    // 返回app
    revert () {
      sessionStorage.clear()
      localStorage.clear()
      if (this.$global.modelState) {
        window.android.exit();
      } else {
        this.$iosMethod(function (bridge) {
          bridge.callHandler('exit', null, function (data) {
          })
        })
      }
    },
    //查电话号码
    findUserInfo () {
      this.$post({
        url: apis.findUserInfo,
        params: {
          token: this.$global.token,
          deviceId: ''
        }
      }).then(res => {
        this.listCoupon(res.data.phoneNumber);
      });
    },
    /**获取消费券列表 */
    listCoupon (userPhone) {
      let _t = this;
      _t.couponList = []
      let params = {
        userPhone: userPhone,
        token: _t.$global.token,
        status: 1,
      }
      this.$post({
        url: apis.listCoupon,
        params: params
      }).then(res => {
        _t.couponList = res.data

      });
    },
    /**h5跳小程序 */
    openApplet () {
      let shareUrl = window.location.href.split('#')[0]
      let _this = this
      _this.$post2({
        url: _this.$global.baseUrl + 'getWechatSDKSignature',
        params: {
          shareUrl: shareUrl
        }
      }).then(res => {
        wx.config({
          debug: false,// 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: _this.$global.appId,         // 必填，公众号的唯一标识，填自己的！
          timestamp: res.data.timestamp, // 必填，生成签名的时间戳，刚才接口拿到的数据
          nonceStr: res.data.nonceStr,  // 必填，生成签名的随机串
          signature: res.data.signature,// 必填，签名，见附录1
          jsApiList: [
            'updateTimelineShareData',
            'updateAppMessageShareData'
          ],
          openTagList: ["wx-open-launch-weapp"], // 填入打开小程序的开放标
        });
        wx.ready(function () {
          console.log('调用成功');
          _this.$nextTick(() => {
            //监听按钮异常
            var btn = document.getElementById('launch-btn');
            console.log(btn)
            btn.addEventListener('launch', function (e) {
              console.log('success');
            });
            btn.addEventListener('error', function (e) {
              console.log('fail', e.detail);
            });
          })
        })
        wx.error(function (res) {

          Toast("微信授权失败");

        });
      }).catch(err => {
        this.loading = false
        Toast(err.message);
      });
    }
  },
  created () {
    this.loading = true;
    if (this.$route.query.backApp == 1) {
      sessionStorage.setItem('backAppHome', 1);
      this.backApp = 1
    }
    if (sessionStorage.getItem('backAppHome') == 1) {
      this.backApp = 1
    }

    setTimeout(() => {
      this.loadList('ticket')
      this.loadList('enjoy')
      this.loadList('hotel')
      this.loadList('meal')
      this.loadList('gift')
      this.loadSpecial()
      this.getInfo()
      this.findUserInfo() // 查询消费券列表
    }, 2000)
  },
  mounted () {
    //h5跳小程序
    // this.openApplet()
  }
}
