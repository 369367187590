<template>
  <div class="commain">
    <div class="green">
      <van-nav-bar title="冰雪大派送">
        <template #left>
          <van-icon :size="24" color="#ffffff" name="arrow-left" slot="left" @click="revert()" />
        </template>
      </van-nav-bar>
    </div>
    <div class="bg">
      <div class="messageTip" @click="openPop()">
        活动介绍
      </div>
      <img src="../imgs/bg1.png" alt="">
    </div>
    <!-- h5跳小程序 -->
    <!-- <div class="toMiniapp_box">
      <wx-open-launch-weapp id="launch-btn" username="gh_5c46c4642130" path="pages/index/index">
        <component :is="'script'" type="text/wxtag-template">
          <component :is="'style'">
            .btn {
              padding: 12px
            }
          </component>
          <button class="btn">打开小程序</button>
        </component>
      </wx-open-launch-weapp>
    </div> -->
    <!-- 主内容 -->
    <div class="main">
      <!-- 1、票务（峨眉山三大票务）
              2、周边玩乐（演艺、温泉、冰雪、研学、数游峨眉）
              3、酒店（客房）
              4、美食（餐饮）
              5、伴手礼（茶业、文创） -->
      <!-- 票务 -->
      <!--这一坨没得了，多张券-->
      <!--<div class="special" v-if="special.CouponPrcice" @click="goUrlSpecial(special)">
        <img class="special-img" src="../imgs/special.png" alt="">
        <div class="img">
          <img v-if="special.cover" :src="special.cover" alt="">
          <img v-else src="../imgs/goods.png" alt="">
        </div>
        <div class="rr">
          <h4>{{special.name}}</h4>
          <div class="row-sp">
            <div class="cell" v-if="special.postCouponPrice"><p>原价</p><p> <sub> <em>￥</em></sub>{{special.sellPrice?special.sellPrice/100:0}}</p></div>
            <div class="cell" v-else><p></p><p> <sub> <em>￥</em></sub>{{special.sellPrice?special.sellPrice/100:0}}</p></div>
            <div class="cell red" v-if="special.postCouponPrice" ><p>券后价</p><p> <sub> <em>￥</em></sub>{{special.postCouponPrice?special.postCouponPrice/100:0}}</p></div>
          </div>
        </div>
      </div>-->
      <van-loading v-if="loading" size="32" />
      <div class="ticket" v-if="ticket.length > 0">
        <h2><span>优惠·峨眉山票务</span><span></span></h2>
        <!-- 列表 -->
        <div class="list">
          <div class="box" v-for="(item, index) in ticket" :key="index" @click="goUrl(item)">
            <img v-if="item.cover" :src="item.cover" alt="">
            <img v-else src="../imgs/goods.png" alt="">
            <div class="wp">
              <h4>{{ item.name }}</h4>
              <div class="tags_out" v-if="item.tags.length > 0">
                <div class="tag_in" v-for="(i, d) in  item.tags" :key="d">{{ i }}</div>
              </div>
              <p v-if="item.postCouponPrice">
                <span><sub>原价 <em>￥</em></sub>{{ item.sellPrice ? item.sellPrice / 100 : 0 }}</span>
                <span class="red"><sub>券后价 <em>￥</em></sub>{{ item.postCouponPrice ? item.postCouponPrice / 100 : 0
                }}</span>
              </p>
              <p v-else><span><sub> <em>￥</em></sub>{{ item.sellPrice ? item.sellPrice / 100 : 0 }}</span></p>
            </div>
          </div>
        </div>
        <!-- 列表end -->
      </div>
      <!-- 周边玩乐 -->
      <div class="enjoy" v-if="enjoy.length > 0">
        <h2><span>优惠·峨眉山周边玩乐</span><span></span></h2>
        <!-- 列表 -->
        <div class="list">
          <div class="box" v-for="(item, index) in enjoy" :key="index" @click="goUrl(item)">
            <img v-if="item.cover" :src="item.cover" alt="">
            <img v-else src="../imgs/goods.png" alt="">
            <div class="wp">
              <h4>{{ item.name }}</h4>
              <div class="tags_out" v-if="item.tags.length > 0">
                <div class="tag_in" v-for="(i, d) in  item.tags" :key="d">{{ i }}</div>
              </div>
              <p v-if="item.postCouponPrice">
                <span><sub>原价 <em>￥</em></sub>{{ item.sellPrice ? item.sellPrice / 100 : 0 }}</span>
                <span class="red"><sub>券后价 <em>￥</em></sub>{{ item.postCouponPrice ? item.postCouponPrice / 100 : 0
                }}</span>
              </p>
              <p v-else><span><sub> <em>￥</em></sub>{{ item.sellPrice ? item.sellPrice / 100 : 0 }}</span></p>
            </div>
          </div>
        </div>
        <!-- 列表end -->
      </div>
      <!-- 酒店 -->
      <div class="hotel" v-if="hotel.length > 0">
        <h2><span>优惠·峨眉山酒店</span><span></span></h2>
        <!-- 列表 -->
        <div class="list">
          <div class="box" v-for="(item, index) in hotel" :key="index" @click="goUrlHotel(item)">
            <img v-if="item.cover" :src="item.cover" alt="">
            <img v-else src="../imgs/goods.png" alt="">
            <div class="wp">
              <h4>{{ item.name }}</h4>
              <div class="tags_out" v-if="item.tags.length > 0">
                <div class="tag_in" v-for="(i, d) in  item.tags" :key="d">{{ i }}</div>
              </div>
              <p v-if="item.postCouponPrice">
                <span><sub>原价 <em>￥</em></sub>{{ item.sellPrice ? item.sellPrice / 100 : 0 }}</span>
                <span class="red"><sub>券后价 <em>￥</em></sub>{{ item.postCouponPrice ? item.postCouponPrice / 100 : 0
                }}</span>
              </p>
              <p v-else><span><sub> <em>￥</em></sub>{{ item.sellPrice ? item.sellPrice / 100 : 0 }}</span></p>
            </div>
          </div>
        </div>
        <!-- 列表end -->
      </div>
      <!-- 美食 -->
      <div class="meal" v-if="meal.length > 0">
        <h2><span>优惠·峨眉山美食</span><span></span></h2>
        <!-- 列表 -->
        <div class="list">
          <div class="box" v-for="(item, index) in meal" :key="index" @click="goUrl(item)">
            <img v-if="item.cover" :src="item.cover" alt="">
            <img v-else src="../imgs/goods.png" alt="">
            <div class="wp">
              <h4>{{ item.name }}</h4>
              <div class="tags_out" v-if="item.tags.length > 0">
                <div class="tag_in" v-for="(i, d) in  item.tags" :key="d">{{ i }}</div>
              </div>
              <p v-if="item.postCouponPrice">
                <span><sub>原价 <em>￥</em></sub>{{ item.sellPrice ? item.sellPrice / 100 : 0 }}</span>
                <span class="red"><sub>券后价 <em>￥</em></sub>{{ item.postCouponPrice ? item.postCouponPrice / 100 : 0
                }}</span>
              </p>
              <p v-else><span><sub> <em>￥</em></sub>{{ item.sellPrice ? item.sellPrice / 100 : 0 }}</span></p>
            </div>
          </div>
        </div>
        <!-- 列表end -->
      </div>
      <!-- 伴手礼 -->
      <div class="gift" v-if="gift.length > 0">
        <h2><span>优惠·峨眉山伴手礼</span><span></span></h2>
        <!-- 列表 -->
        <div class="list">
          <div class="box" v-for="(item, index) in gift" :key="index" @click="goUrlls(item)">
            <img v-if="item.cover" :src="item.cover" alt="">
            <img v-else src="../imgs/goods.png" alt="">
            <div class="wp">
              <h4>{{ item.name }}</h4>
              <div class="tags_out" v-if="item.tags.length > 0">
                <div class="tag_in" v-for="(i, d) in  item.tags" :key="d">{{ i }}</div>
              </div>
              <p v-if="item.postCouponPrice">
                <span><sub>原价 <em>￥</em></sub>{{ item.sellPrice ? item.sellPrice / 100 : 0 }}</span>
                <span class="red"><sub>券后价 <em>￥</em></sub>{{ item.postCouponPrice ? item.postCouponPrice / 100 : 0
                }}</span>
              </p>
              <p v-else><span><sub> <em>￥</em></sub>{{ item.sellPrice ? item.sellPrice / 100 : 0 }}</span></p>
            </div>
          </div>
        </div>
        <!-- 列表end -->
      </div>
    </div>

    <!--<tabbar :num="1"></tabbar>-->
    <van-popup v-model:show="showCoupon" closeable>
      <div class="coupon" @click="goUrlSpecial(special)" v-if="special.CouponPrcice">
        <div class="content">
          <h4><sub>¥</sub> <em>{{ special.CouponPrcice ? special.CouponPrcice / 100 : 0 }}</em> 元消费劵</h4>
          <p> {{ special.name }}</p>
        </div>
      </div>
    </van-popup>
    <!--新优惠券弹窗-->
    <van-popup v-model:show="showCoupon" closeable>
      <div class="coupon">
        <div class="c_items">
          <div class="ci_detail" v-for="(item, index) in couponList" :key="index" @click="couponListGoods(item)">
            <div class="d_f ali_b">
              <span class="c_tag">￥<span class="c_pri">{{ item.couponAmount ? item.couponAmount / 100 : 0 }}</span></span>
              <span class="ci_name">{{ item.couponName }}</span>
            </div>
            <div class="c_sub_tip">{{ item.couponDesc }}</div>
          </div>
        </div>
        <div class="c_tip t_a">上滑查看更多的消费劵</div>
        <div class="c_btn" @click="$router.push('/skiCouponList')">我的消费劵</div>
      </div>
    </van-popup>
    <van-popup round v-model:show="showMessage" class="message">
      <div class="pop">
        <div class="tt">活动介绍</div>
        <div class="content" v-html="list.content"></div>
        <div class="close" @click="showMessage = false">关闭</div>
      </div>
    </van-popup>

    <!--返回app按钮-->
    <!--<div @click="revert" class="revert">
      <img src="../imgs/revert.png" alt="">
    </div>-->
  </div>
</template>
<script src='./skiHome.js'></script>
<style lang="scss" scoped src='./skiHome.scss'></style>
